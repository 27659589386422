import { fetchLatestCryptocurrencyListings } from "../CryptoPairSymbols";
import { gateioSymbols } from "../CryptoPairSymbols";
import WebSocketManager from "../WebSocketManagerGateio";

const gateioUrl = 'wss://api.gateio.ws/ws/v4/';

//price data with 
const gateioPriceDataMsg =(symbolsArray) => ({
    "time": 1545404023,
    "channel": "spot.tickers",
    "event": "subscribe", 
    // "payload": ["BTC_USDT"]
    "payload": symbolsArray
});


const gateioWebSocket = {
 async connect(onMessageCallback) {
    try {
      const { success, response } = await fetchLatestCryptocurrencyListings();
      if (success) {
        // const symbols = response.data.data
        // .slice(0, 10)
        // .map(crypto => crypto.symbol + "_USDT")
        // .filter(symbol => symbol !== "USDT_USDT");

        const symbols =gateioSymbols;
        
        // console.log(symbols,"SYMBOLS");
        const gateioPriceData = gateioPriceDataMsg(symbols); 
        WebSocketManager.onMessage(onMessageCallback);  // Set the message handler
        WebSocketManager.initConnection('gateio', gateioUrl, gateioPriceData); //which method to Subscription data put it here
      }
      else {
        console.error('Failed to fetch cryptocurrency symbols');
      }

    } catch (error) {
      console.error('Error connecting to gateio WebSocket:', error.message);
    }
  },

  disconnect() {
    WebSocketManager.closeConnection('gateio');
  },
};

export default gateioWebSocket;
