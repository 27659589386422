import React, { useEffect, useState } from 'react';
import { FaCopy } from 'react-icons/fa';

const TransactionTable = () => {
  const generateRandomTransaction = () => {
    const wallets = ['Spot Wallet', 'Savings Wallet', 'Margin Wallet'];
    const coins = ['BTC', 'ETH', 'BNB', 'SOL', 'USDC', 'XRP', 'DOGE', 'TON', 'ADA', 'TRX'];
    const statuses = ['Pending','Completed','Completed', 'Failed'];
    const types = ['Deposit', 'Withdrawal'];
    const onChains = ['On-chain', 'Off-chain'];

    const getRandom = (arr) => arr[Math.floor(Math.random() * arr.length)];

    return {
      time: new Date().toLocaleString(),
      type: getRandom(types),
      wallet: getRandom(wallets),
      coin: getRandom(coins),
      amount: (Math.random() * 1000).toFixed(2),
      destination: '0x' + Math.random().toString(16).slice(2, 42),
      txId: Math.random().toString(36).slice(2, 12),
      status: getRandom(statuses),
      onChain: getRandom(onChains),
    };
  };

  const [transactions, setTransactions] = useState([
    generateRandomTransaction(),
    generateRandomTransaction(),
    generateRandomTransaction(),
  ]);

  
  useEffect(() => {
    const interval = setInterval(() => {
      setTransactions((prevTransactions) => [
        generateRandomTransaction(),
        ...prevTransactions, 
      ]);
    }, 3000); // 3 seconds 60000(1min)

    return () => clearInterval(interval);
  }, []);

  // const copyToClipboard = (text) => {
  //   navigator.clipboard.writeText(text)
  //     .then(() => {
  //       alert('Copied to clipboard!'); // Optional: Notify user
  //     })
  //     .catch(err => {
  //       console.error('Failed to copy: ', err);
  //     });
  // };

  return (
    <div className="overflow-x-auto">
      <table className="min-w-full table-auto bg-gray-800 text-white transac-table">
        <thead className="bg-gray-900">
          <tr>
            <th className="px-4 py-2 text-left">Time</th>
            <th className="px-4 py-2 text-left">Type</th>
            <th className="px-4 py-2 text-left">Deposit Wallet</th>
            <th className="px-4 py-2 text-left">Coin</th>
            <th className="px-4 py-2 text-left">Amount</th>
            <th className="px-4 py-2 text-left">Destination</th>
            <th className="px-4 py-2 text-left">TxID</th>
            <th className="px-4 py-2 text-left">Status</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((tx, index) => (
            <tr key={index} className="border-b border-gray-700">
              <td className="px-4 py-2">{tx.time}</td>
              <td className="px-4 py-2">{tx.type}</td>
              <td className="px-4 py-2">{tx.wallet}</td>
              <td className="px-4 py-2">{tx.coin}</td>
              <td className="px-4 py-2">{tx.amount}</td>
              <td className="px-4 py-2">
                {tx.destination}
                {/* <FaCopy
                  className="ml-2 cursor-pointer hover:text-blue-400"
                  onClick={() => copyToClipboard(tx.destination)}
                /> */}
              </td>
              <td className="px-4 py-2">
                {tx.txId}
                {/* <FaCopy
                  className="ml-2 cursor-pointer hover:text-blue-400"
                  onClick={() => copyToClipboard(tx.txId)}
                /> */}
              </td>
              <td className="px-4 py-2 text-green-500">{tx.status}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TransactionTable;
