import React, { useState } from "react";
import SidebarItem from "./SidebarItem";
import { MdOutlineDashboard } from "react-icons/md";
import { BsFileBarGraphFill } from "react-icons/bs";
import { SiBinance, SiKucoin } from "react-icons/si";
import { IoMdHelpCircleOutline } from "react-icons/io";

const Sidebar = ({ onMenuItemClick }) => {
  const [activeItem, setActiveItem] = useState("Dashboard");

  const menuItems = [
    // { name: "Dashboard", icon: <MdOutlineDashboard /> },
    { name: "Celex", icon: <BsFileBarGraphFill /> },
    { name: "Transaction History Binance", icon: <SiBinance />},
    { name: "Transaction History KuCoin", icon: <SiKucoin />},
    // { name: "Help", icon: <IoMdHelpCircleOutline /> },
  ];

  const handleMenuClick = (name) => {
    // if (name == "Message") {
    //   window.location.href = "mailto:support@wondercryptobot.net";
    // }
    setActiveItem(name);
    onMenuItemClick(name); // Pass the active menu item to the parent
  };

  return (
    <div className="h-screen w-64 bg-[#21242D] text-white mobile-left">
      <ul className="space-y-4 py-[40px] px-[20px]">
        {menuItems.map((item) => (
          <SidebarItem
            key={item.name}
            name={item.name}
            icon={item.icon}
            isActive={activeItem === item.name}
            onClick={() => handleMenuClick(item.name)}
          />
        ))}
      </ul>
    </div>
  );
};

export default Sidebar;
