import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { FaBitcoin } from "react-icons/fa";

const CoinIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    className="w-6 h-6"
  >
    <defs>
      <radialGradient id="coinGradient" cx="50%" cy="50%" r="50%" fx="50%" fy="50%">
        <stop offset="0%" style={{ stopColor: "#FFD700", stopOpacity: 1 }} /> {/* Inner glow */}
        <stop offset="100%" style={{ stopColor: "#FFC700", stopOpacity: 1 }} /> {/* Outer glow */}
      </radialGradient>
    </defs>
    <circle cx="12" cy="12" r="10" fill="url(#coinGradient)" stroke="#DAA520" strokeWidth="2" />
    <text
      x="12"
      y="16"
      textAnchor="middle"
      fontSize="10"
      fill="#DAA520"
      fontWeight="bold"
    >
      $
    </text>
  </svg>
);

const NotificationComponent = () => {
  const [notifications, setNotifications] = useState([]);

  useEffect(() => {
    const generateNotification = () => {
      const randomAmount = Math.floor(Math.random() * (200 - 10 + 1)) + 10;
      const currencies = ["BTC", "ETH", "LTC", "XRP", "DOGE"];
      const randomFromCurrency =
        currencies[Math.floor(Math.random() * currencies.length)];
      const randomToCurrency =
        currencies[Math.floor(Math.random() * currencies.length)];

      const newNotification = {
        id: Date.now(), // Unique ID for each notification
        amount: randomAmount,
        fromCurrency: randomFromCurrency,
        toCurrency: randomToCurrency,
      };

      setNotifications((prev) => {
        const updatedNotifications = [...prev, newNotification];
        // Keep only the last 5 notifications
        if (updatedNotifications.length > 5) {
          updatedNotifications.shift(); // Remove the oldest notification
        }
        return updatedNotifications;
      });

      setTimeout(() => {
        setNotifications((prev) =>
          prev.filter((notification) => notification.id !== newNotification.id)
        );
      }, 2000); // Remove each notification after 2 seconds
    };

    const randomInterval = () => {
      const interval = Math.random() * (70000 - 60000) + 60000; // 1 to 1.1 minutes
      generateNotification();
      setTimeout(randomInterval, interval);
    };

    const timeoutId = setTimeout(randomInterval, 3000); // Start after 3 seconds

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="fixed bottom-4 right-4 z-50 space-y-2">
      <AnimatePresence>
        {notifications.map((notification) => (
          <motion.div
            key={notification.id}
            initial={{ y: 50, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ y: 50, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="bg-gradient-to-r from-green-400 to-blue-500 text-white p-4 rounded-lg shadow-lg max-w-sm relative"
            style={{ height: "130px" }} // Doubled the height
            role="alert"
            aria-live="polite"
          >
            <div className="flex flex-col h-full justify-between">
              <div className="flex items-center">
              <CoinIcon className="text-yellow-400 text-2xl mr-2" />
                <p className="font-semibold text-sm">
                  Booked profit on {notification.fromCurrency}/
                  {notification.toCurrency} exchange
                </p>
              </div>
              <div className="text-center">
                <p className="text-3xl font-bold">${notification.amount}</p>
              </div>
              <button
                onClick={() =>
                  setNotifications((prev) =>
                    prev.filter((n) => n.id !== notification.id)
                  )
                }
                className="absolute top-1 right-1 text-white hover:text-gray-200 focus:outline-none"
                aria-label="Close notification"
              >
                <svg
                  className="h-4 w-4"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
    </div>
  );
};

export default NotificationComponent;
