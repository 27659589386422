import React, { useState, useEffect } from "react";
import KrakenWebSocket from "../services/kraken/KrakenWebSocket";
import gateioWebSocket from "../services/gateio/gateiowebsocket";
import binanceWebSocket from "../services/binance/binancewebsocket";
import { fetchLatestCryptocurrencyListings } from "../services/CryptoPairSymbols";
import NotificationComponent from "./NotificationComponent";
import htxWebSocket from "../services/htx/HtxWebSocket";
import { fetchKucoinBulletPublic } from "../services/kucoin/KuCoinServApi";
import kuCoinWebSocket from "../services/kucoin/KuCoinWebSocket";
import bitfinexWebSocket from "../services/bitfinex/BitfinexWebSocket";
import { cryptoListPairSymbols } from "../services/CryptoPairSymbols";
import WebSocketTrading from "../services/binance/BinanceTrade";


const TableData = () => {
  const [tooltip, setTooltip] = useState(null);
  // const [krakenData, setKrakenData] = useState({});
  const [gateioData, setGateioData] = useState({});
  const [binanceData, setBinanceData] = useState({});
  const [kucoinData, setKucoinData] = useState({});
  const [htxData, setHtxData] = useState({});
  const [symbols, setSymbols] = useState([]);
  const [quantity, setQuantity] = useState("");
  const [selectedExchange, setSelectedExchange] = useState(null);
  const [selectedSymbol, setSelectedSymbol] = useState(null);
  const [priceMessages, setPriceMessage] = useState([]);

  const handleClick = (exchange,priceData,symbol, event) => {
    // Stop event propagation to prevent immediate close
    
    event.stopPropagation();
    setSelectedExchange(exchange);
    setSelectedSymbol(symbol);
    // Set the tooltip position and data
    setTooltip({
      priceData,
      x: event.clientX,
      y: event.clientY,
    });
  };

  const handleClickOutside = (event) => {
    if (!event.target.closest(".tooltip")) {
      handleCloseTradeSocketAPI();// Disconnect the websocket api 
      setTooltip(null); // Close tooltip when clicking outside
    }
  };

  const fetchExchangeInfoBinance = async ()=> {
    try {
      const response = await fetch(`https://api.binance.com/api/v3/exchangeInfo?symbol=${selectedSymbol}`);
      if (!response.ok) {
        throw new Error('Failed to fetch exchange info');
      }
      const data = await response.json();
      
      const filterData = {
        minQty: data.symbols[0].filters[1].minQty,
        minNotional: data.symbols[0].filters[6].minNotional
      };
              
      return filterData;
    } catch (err) {
      console.error('Error:', err.message);
    }
  };

  const handleTrading = async(side) => {
    const missingFields = [];

    if (!selectedSymbol) missingFields.push("Symbol");
    if (!quantity) missingFields.push("quantity");
    if (!side) missingFields.push("Side");
    if (!selectedExchange) missingFields.push("Exchange");
    
    if (missingFields.length > 0) {
      alert(`The following fields are required: ${missingFields.join(", ")}`);
      return;
    }
    const reqParamData = {
      selectedSymbol,
      quantity,
      side,
      selectedExchange
    };
    
    if (selectedExchange === "binance"){
      console.log("from Binance");
      // const exchangeFilter =await fetchExchangeInfoBinance();
      // const minNotionQyt = exchangeFilter.minNotional / binanceData[reqParamData.selectedSymbol]?.lastPrice

      // if(reqParamData.quantity < exchangeFilter.minQty ){
      //   alert(`Order quantity is below the minimum quantity. Minimum required quantity: ${exchangeFilter.minQty}`);
      //   return
      // }
      // if(reqParamData.quantity < minNotionQyt){
      //   alert(`Order quantity is below the minimum notional value. Minimum required quantity: ${minNotionQyt}`);
      //  return
      // }
      
      // const wsTrading = new WebSocketTrading();
      // wsTrading.initConnection(reqParamData);
      // wsTrading.onMessage((message) => {
      //   // console.log('Received messagessssss:', message);
      //   if(message.result.status === "FILLED"){
      //   alert(message.result.status,`SUCCESFULLY${side}`)
      //   }
      //   else{
      //     alert(message,"Transection Failed");
      //   }
      // });
    }
    else if (selectedExchange === "kucoin"){
      console.log("from Kucoin");
      //   try {
      //     const response = await fetch('http://localhost:5000/kucoin/place-order', {
      //       method: 'POST',
      //       headers: {
      //         'Content-Type': 'application/json',
      //       },
      //       body: JSON.stringify({
      //         symbol: reqParamData.selectedSymbol,  // Example: 'BTC-USDT'
      //         funds: reqParamData.quantity,         // The amount you want to trade
      //         side: reqParamData.side,              // BUY or SELL
      //         clientOid: `order_${Date.now()}`, // A unique ID for the order
      //         tradeType: 'TRADE'       // Assuming default to 'TRADE'
      //       }),
      //     });
    
      //     const data = await response.json();
      //     console.log('KuCoin order response:', data);
      //   } catch (error) {
      //     console.error('Error placing KuCoin order:', error);
      //   }
    }
    else if (selectedExchange === "gateio"){
      console.log("from gateio");
      
    }
    else if (selectedExchange === "htx"){
      console.log("from htx");
      
    }
  };

  const handleCloseTradeSocketAPI = ()=>{
      const wsTrading = new WebSocketTrading();
      wsTrading.closeConnection("binance");
  }
  

  const handleTopCurrency = async () => {
    const { success, response } = await fetchLatestCryptocurrencyListings();
    if (success) {
      // const symbols = response.data.data
      //   .slice(0, 11)
      //   .map(crypto => crypto.symbol + "/USDT")
      //   .filter(symbol => symbol !== "USDT/USDT");
      const symbols = cryptoListPairSymbols      
      return symbols;
    }
    return [];
  };

  let dynamicQuoteCurrencies = [];

  // Function to fetch quote currencies dynamically
  const fetchQuoteCurrencies = async () => {
    // Simulate an API call to fetch known quote currencies
    const { success, response } = await fetchLatestCryptocurrencyListings();
    if (success) {
      const quotes = response?.data?.data.map((crypto) => crypto.symbol);
      dynamicQuoteCurrencies = [...new Set(quotes)];
      return dynamicQuoteCurrencies;
    }
    return [];
  };

  // Function to format trading pair dynamically
  const formatTradingPair = (pair) => {
    pair = pair?.toUpperCase();

    if (pair?.includes("_")) {
      return pair.replace("_", "/");
    }


    if (pair?.includes("-")) {
      return pair.replace("-", "/");
    }
    // Check if the pair is in the format "market.{symbol}.ticker"
    if (pair?.includes("MARKET.")) {

      const symbol = pair.split('.')[1].replace('.TICKER', '');

      const quoteCurrencyLength = 4; //(like "USDT", "USD", etc.)

      const baseCurrency = symbol.slice(0, -quoteCurrencyLength);
      const quoteCurrency = symbol.slice(-quoteCurrencyLength);
       
      return `${baseCurrency}/${quoteCurrency}`;
    }

    if (pair?.includes("/")) {
      return pair;
    }

    // Use dynamically fetched quote currencies
    fetchQuoteCurrencies();
    for (let quote of dynamicQuoteCurrencies) {
      if (pair?.endsWith(quote)) {
        const base = pair.substring(0, pair.length - quote.length);
        return `${base}/${quote}`;
      }
    }
    return pair;
  };

  const PRICE_DIFF_THRESHOLD = 0.2; // Define threshold for 0.2% difference

const calculatePriceDifferences = async() => {
  // const exchanges = [gateioData, binanceData, kucoinData, htxData];
  // const differences = [];
  let messages = [];
  symbols.forEach((symbol) => {
    let newSymbol = symbol.replace("/", "");
    // let newKucoinSymbol = symbol.replace("-", "/");
    
    if (kucoinData[symbol] && binanceData[newSymbol]) {
      const kucoinComPri = parseFloat(kucoinData[symbol]?.lastPrice);
      const binanceComPri = parseFloat(binanceData[newSymbol]?.lastPrice);
  
      // Check if prices are valid numbers
      if (!isNaN(kucoinComPri) && !isNaN(binanceComPri)) {
        // const difference = ((kucoinComPri - binanceComPri) / ((kucoinComPri + binanceComPri) / 2)) * 100;
        const difference = kucoinComPri - binanceComPri;
        const higherExchange = difference > 0 ? "Kucoin" : "Binance";
        const lowerExchange = difference > 0 ? "Binance" : "Kucoin";
      
        messages.push({
          symbol,
          kucoinComPri,
          binanceComPri,
          priceDifference: Math.abs(difference).toFixed(2),
          higherExchange,
          lowerExchange
        });
      } else {
        console.log(`Invalid price data for symbol ${symbol}`);
      }
    } 
  });
  setPriceMessage(messages);

  // return differences; // Return the calculated differences
};

  useEffect(() => {
    const fetchSymbols = async () => {
      const fetchedSymbols = await handleTopCurrency();
      setSymbols(fetchedSymbols);

    };
    fetchSymbols();
    
    // getKucoinData();

    // Kraken WebSocket connection
    // KrakenWebSocket.connect((data) => {
    //   const krakenArray = Array.isArray(data?.data) ? data?.data : [];

    // Loop through each symbol and update the state dynamically
    //   krakenArray.forEach((item) => {
    //     setKrakenData((prevData) => ({
    //       ...prevData,
    //       [item?.symbol]: {
    //         lastPrice: item?.last,
    //         percentChange: item?.change_pct,
    //       },
    //     }));
    //   });
    // });

    // Gateio WebSocket connection
    gateioWebSocket.connect((data) => {
      const formattedSymbol = formatTradingPair(data?.result?.currency_pair);

      setGateioData((prevData) => ({
        ...prevData,
        [formattedSymbol]: {
          lastPrice: data?.result?.last,
          percentChange: data?.result?.change_percentage,
             symbol:data?.result?.currency_pair,
        },
      }));
    });

    // Binance WebSocket connection
    binanceWebSocket.connect((data) => {
      const formattedSymbol = formatTradingPair(data?.s);

      setBinanceData((prevData) => ({
        ...prevData,
        [formattedSymbol]: {
          lastPrice: data?.c,
          percentChange: data?.P,
          symbol:data?.s,
        },
      }));
    });

        // KuCoin WebSocket connection
        kuCoinWebSocket.connect((data) => {
          const formattedSymbol = formatTradingPair(data?.data?.data?.symbol);

          setKucoinData((prevData) => ({
            ...prevData,
            [formattedSymbol]: {
              lastPrice: data?.data?.data?.close,
              percentChange: data?.data?.data?.changeRate,
              symbol:data?.data?.data?.symbol,
            },
          }));
        });

      // HTX WebSocket connection
      htxWebSocket.connect((data) => {
        const formattedSymbol = formatTradingPair(data?.ch);
        setHtxData((prevData) => ({
          ...prevData,
          [formattedSymbol]: {
            lastPrice: data?.tick?.lastPrice,
            percentChange: ((data?.tick?.close - data?.tick?.open) / data?.tick?.open) * 100,
            symbol:data?.ch,
          },
        }));
        // console.log(formattedSymbol,"RETURNED SYMBOLS");
        
      });

    // BITFINEX WebSocket connection
    // bitfinexWebSocket.connect((data) => {
    // // const formattedSymbol = formatTradingPair(data?.result?.currency_pair); // Format the symbol
    //  console.log(data[1],"CHECKINGDATA");

    // // setGateioData((prevData) => ({
    // //   ...prevData,
    // //   [formattedSymbol]: {
    // //     lastPrice: data?.result?.last,
    // //     percentChange: data?.result?.change_percentage,
    // //   },
    // // }));
    //  });

    document.addEventListener("click", handleClickOutside);
    return () => {
      // Cleanup WebSocket connections on component unmount
      KrakenWebSocket.disconnect();
      gateioWebSocket.disconnect();
      binanceWebSocket.disconnect();
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(()=>{
     calculatePriceDifferences(); 
  },[gateioData, binanceData, kucoinData, htxData,symbols])

  function formatToFixed(str) {
    if (!str) return "";
    str = str + "";
    // Split the string by the decimal point
    const parts = str.split(".");

    // Check if there are at least two parts
    if (parts.length > 1) {
      // Join all but the last part with a decimal point and keep the last part
      const numberPart =
        parts.slice(0, -1).join(".") + "." + parts[parts.length - 1];

      // Convert to number and format to 3 decimal places
      const formattedNumber = parseFloat(numberPart).toFixed(3);

      return formattedNumber;
    } else {
      // If there are no decimal points, just return the original string formatted to 3 decimal places
      return parseFloat(str).toFixed(3);
    }
  }
  // Individual disconnect handlers for each WebSocket
  const handleKrakenDisconnect = () => {
    KrakenWebSocket.disconnect();
  };

  const handleGateioDisconnect = () => {
    gateioWebSocket.disconnect();
  };

  const handleBinanceDisconnect = () => {
    binanceWebSocket.disconnect();
  };

  return (
    <div className="table-container rounded-[10px] relative">
      <NotificationComponent />
      <div className="flex flex-col h-screen overflow-x-auto">
      
      <table className="crypto-table">
        <thead>
          <tr className="border-b border-0 border-solid">
            <th className="!font-thin">Currency Name</th>

            <th colSpan="2" className="!font-thin !h-2">
              GATE.IO
            </th>
            <th colSpan="2" className="!font-thin !h-2">
              BINANCE
            </th>
            <th colSpan="2" className="!font-thin !h-2">
              KUCOIN
            </th>
            <th colSpan="2" className="!font-thin !h-2">
              HTX
            </th>
            {/* <th colSpan="2" className="!font-thin !h-2">
              UPBIT
            </th>
            <th colSpan="2" className="!font-thin !h-2">
              COINBASE
            </th>
            <th colSpan="2" className="!font-thin !h-2">
              BITFINEX
            </th> */}
          </tr>
          <tr className="border-b !font-thin">
            <th>Symbol</th>
            <th>Current Price</th>
            <th>Price Difference</th>
            <th>Current Price</th>
            <th>Price Difference</th>
            <th>Current Price</th>
            <th>Price Difference</th>
            <th>Current Price</th>
            <th>Price Difference</th>
            {/* <th>Current Price</th>
            <th>Price Difference</th>
            <th>Current Price</th>
            <th>Price Difference</th>
            <th>Current Price</th>
            <th>Price Difference</th> */}
          </tr>
        </thead>
        <tbody className="text-center">
          {symbols.map((symbol, index) => {
            let newSymbol = symbol.replace("/", "");
            let newKucoinSymbol = symbol.replace("-", "/");
            return (
              <tr key={index} className="border-b">
                <td className="flex flex-row">
                  <span className="">🟠</span> {symbol}
                </td>

                {/* Kraken Price Section */}
                {/* <td
                  className="cursor-pointer"
                  onClick={(event) =>
                    handleClick(krakenData[symbol]?.lastPrice, event)
                  }
                >
                  {krakenData[symbol]?.lastPrice
                    ? "$" + krakenData[symbol]?.lastPrice.toFixed(3)
                    : "--"}
                </td>
                <td
                  className="max-h-4"
                  style={{
                    color:
                      krakenData[symbol]?.percentChange < 0
                        ? "#FF3838"
                        : "#00FF00",
                  }}
                >
                  {krakenData[symbol]?.percentChange
                    ? `${krakenData[symbol]?.percentChange.toFixed(3)}%`
                    : "--"}
                </td> */}

                {/* Gate.io Price Section */}
                <td
                  className="cursor-pointer"
                  onClick={(event) =>
                    handleClick("gateio",quantity,gateioData[symbol]?.symbol, event)
                  }
                >
                  {gateioData[symbol]?.lastPrice
                    ? "$" + formatToFixed(gateioData[symbol]?.lastPrice)
                    : "--"}
                </td>
                <td
                  className="max-h-4"
                  style={{
                    color:
                      gateioData[symbol]?.percentChange < 0
                        ? "#FF3838"
                        : "#00FF00",
                  }}
                >
                  {gateioData[symbol]?.percentChange
                    ? `${formatToFixed(gateioData[symbol]?.percentChange)}%`
                    : "--"}
                </td>

                {/* Binance Price Section */}
                <td
                  onClick={(event) =>
                    handleClick("binance",quantity,binanceData[newSymbol]?.symbol, event)
                  }
                >
                  {binanceData[newSymbol]?.lastPrice
                    ? "$" + formatToFixed(binanceData[newSymbol]?.lastPrice)
                    : "--"}
                </td>
                <td
                  className="max-h-4 cursor-pointer"
                  style={{
                    color:
                      binanceData[newSymbol]?.percentChange < 0
                        ? "#FF3838"
                        : "#00FF00",
                  }}
                >
                  {binanceData[newSymbol]?.percentChange
                    ? `${formatToFixed(binanceData[newSymbol]?.percentChange)}%`
                    : "--"}
                </td>

                {/* Kucoin Price Section */}
                <td
                  className="cursor-pointer"
                  onClick={(event) =>
                    handleClick("kucoin",quantity,kucoinData[newKucoinSymbol]?.symbol, event)
                  }
                >
                  {kucoinData[newKucoinSymbol]?.lastPrice
                    ? "$" + formatToFixed(kucoinData[newKucoinSymbol]?.lastPrice)
                    : "--"}
                </td>
                <td
                  className="max-h-4"
                  style={{
                    color:
                      kucoinData[newKucoinSymbol]?.percentChange < 0
                        ? "#FF3838"
                        : "#00FF00",
                  }}
                >
                  {kucoinData[newKucoinSymbol]?.percentChange
                    ? `${formatToFixed(kucoinData[newKucoinSymbol]?.percentChange)}%`
                    : "--"}
                </td>

                {/* HTX Price Section */}
                <td
                  className="cursor-pointer"
                  onClick={(event) => {                    
                    handleClick("htx",quantity,htxData[symbol]?.symbol, event)
                  }

                  }
                >
                  {htxData[symbol]?.lastPrice
                    ? "$" + formatToFixed(htxData[symbol]?.lastPrice)
                    : "--"}
                </td>
                <td
                  className="max-h-4"
                  style={{
                    color:
                      htxData[symbol]?.percentChange < 0
                        ? "#FF3838"
                        : "#00FF00",
                  }}
                >
                  {htxData[symbol]?.percentChange
                    ? `${formatToFixed(htxData[symbol]?.percentChange)}%`
                    : "--"}
                </td>

                {/* <td className="max-h-4" style={{ color: "#00FF00" }}>
                  {" "}
                  --{" "}
                </td>
                <td className="max-h-4" style={{ color: "#00FF00" }}>
                  {" "}
                  --{" "}
                </td>

                <td className="max-h-4" style={{ color: "#00FF00" }}>
                  {" "}
                  --{" "}
                </td>
                <td className="max-h-4" style={{ color: "#00FF00" }}>
                  {" "}
                  --{" "}
                </td>

                <td className="max-h-4" style={{ color: "#00FF00" }}>
                  {" "}
                  --{" "}
                </td>
                <td className="max-h-4" style={{ color: "#00FF00" }}>
                  {" "}
                  --{" "}
                </td> */}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="bg-gray-600 mt-4 p-4">
    <div className="grid grid-cols-3 gap-4 text-white font-semibold grid-mob">
      {priceMessages.map((msg, index) => (
        <div key={index} className="flex flex-col bg-gray-700 p-4 rounded-lg">
          <span className="text-pink-500">Symbol: {msg.symbol}</span>
          <span className="text-white">Kucoin:${msg.kucoinComPri}</span>
          <span className="text-white">Binance:${msg.binanceComPri}</span>
          <span className="text-blue-500">Price Difference:${msg.priceDifference}</span>
          <span className="text-green-500">Higher: {msg.higherExchange}</span>
          <span className="text-red-500">Lower: {msg.lowerExchange}</span>
        </div>
      ))}
    </div>
  </div>
</div>
      {tooltip && (
        <div
          className="tooltip"
          style={{
            position: "absolute",
            top: `${tooltip.y}px`,
            left: `${tooltip.x}px`,
            backgroundColor: "black",
            color: "white",
            padding: "16px",
            borderRadius: "10px",
            zIndex: 1000,
            whiteSpace: "nowrap",
          }}
          onClick={(e) => e.stopPropagation()} // Prevent closing when interacting with the tooltip
        >
<div>
      <p>
        Quantity:{""}
        <input
          type="number"
          // placeholder="$"
          value={quantity}
          onChange={(e) => setQuantity(e.target.value)}
          className="bg-transparent text-[#fff]"
        />
      </p>
      <div className="flex gap-[8px] py-[10px]">
        <button
          className="bg-[#4b5563] text-white px-[10px] py-[5px] rounded"
          onClick={() => setQuantity(10)}
        >
          10
        </button>
        <button
          className="bg-[#4b5563] text-white px-[10px] py-[5px] rounded"
          onClick={() => setQuantity(20)}
        >
          20
        </button>
        <button
          className="bg-[#4b5563] text-white px-[10px] py-[5px] rounded"
          onClick={() => setQuantity(30)}
        >
          30
        </button>
      </div>
      {/* <p className="py-[10px]">4999 Coins</p> */}
      <div className="flex justify-between gap-[8px]">
        <button className="buy-btn bg-[#61C277] py-[5px] px-[20px] rounded-[7px] text-white uppercase w-full" 
        onClick={() => handleTrading("BUY")}>
          Buy
        </button>
        <button className="sell-btn bg-[#FF3E3E] py-[5px] px-[20px] rounded-[7px] text-white uppercase w-full"
        onClick={() => handleTrading("SELL")}>
          Sell
        </button>
      </div>
      </div>
        </div>
      )}
    </div>
  );
};

export default TableData;
